<template>
    <Modal class="menu-set-link" @close="closeForm()" :opened="opened" :close_click_outside="false">
        <template v-slot:header>
            {{ $t('cms.set_link')}}
        </template>
        
        <template v-slot:body>
            <form class="set-link-form" @submit.prevent="handleSubmit">
                <div class="option" :class="{active: pick == 'link'}" @click="pick = 'link'">
                    <input type="radio" id="pick_link" name="pick" v-model="pick" v-bind:value="'link'" />
                    <label for="pick_link">{{ $t('cms.direct_link')}}</label>
                    <input type="text" id="link" name="link" v-model="link" @change="pick = 'link'"/>
                </div>
                <div class="option" :class="{active: pick == 'page'}" @click="pick = 'page'">
                    <input type="radio" id="pick_page" name="pick" v-model="pick" v-bind:value="'page'" />
                    <label for="pick_page">{{ $t('cms.content_page')}}</label>
                    <BaseButton type="button" @click="openModalSelection(true)">{{ $t('controls.select') }}</BaseButton>
                    <RouterLinkLocal target="_blank" class="page-title" :link="'url.project/:project_code/url.content_pages/!'+page.code" v-if="page">
                        {{ page.title }}
                    </RouterLinkLocal>
                </div>
                <div class="option" :class="{active: pick == 'no_link'}" @click="pick = 'no_link'">
                    <input type="radio" id="pick_no_link" name="pick" v-model="pick" v-bind:value="'no_link'" />
                    <label for="pick_no_link">{{ $t('cms.no_link')}}</label><br>
                </div>
                
                <div class="buttons-block">
                    <BaseButton type="submit" class="btn-success accept-button">
                        {{ $t('controls.confirm')  }}
                    </BaseButton>
                    <BaseButton type="button" class="btn-danger" @click="closeForm()">
                        {{ $t('controls.cancel') }}
                    </BaseButton>
                </div>
            </form>
        </template>
    </Modal>
        <teleport to="#app">
            <PagesSelection :select="true"  :opened="openedModalSelection" @close="openModalSelection(false)" @pickItem="setPage({data: $event})"/>
        </teleport>
</template>

<script>
import Modal from "@common/components/Modal";
import { defineAsyncComponent, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "MenuSetLink",
    components: {
        Modal,
        PagesSelection: defineAsyncComponent(() => import(/* webpackChunkName: "pages-selection" */'@admin/components/cms/content/PagesSelection')),
    },
    emits: ['close'],
    props: {
        opened: Boolean,
        baseData: {
            type: Object,
            default: () => null
        },
    },
    setup(props,context){
        const code = ref('');

        const pick = ref('');
        const link = ref('');
        const page = ref(null);

        watchEffect(() => {
            if(props.baseData === null){
                return;
            }
            code.value = props.baseData.code;
            if(props.baseData.contentPageCode && props.baseData.contentPage){
                page.value = props.baseData.contentPage;
                pick.value = 'page';
                link.value = '';
            } else if(props.baseData.direct_link){
                link.value = props.baseData.direct_link;
                pick.value = 'link';
                page.value = null;
            } else {
                pick.value = 'no_link';
                link.value = '';
                page.value = null;
            }
        });
        const closeForm = () => {
            context.emit('close');
        }


        const openedModalSelection = ref(false);
        const openModalSelection = (value) => {
            openedModalSelection.value = value;
        }

        const setPage = ({data}) => {
            page.value = data;
            pick.value = 'page';
        }

        const store = useStore();
        
        const handleSubmit = () => {
            let data = {
                direct_link: '',
                contentPageCode: '',
            };
            if(pick.value === 'page' && page.value){
                data.contentPageCode = page.value.code;
            } else if(pick.value === 'link' && link.value){
                data.direct_link = link.value;
            }
            store.dispatch('menu_item/updateItem',{data, code: code.value});
            closeForm();
        }

        return {
            closeForm,
            pick,
            link,
            page,
            openedModalSelection,
            openModalSelection,
            setPage,
            handleSubmit
        }
    }
}
</script>

<style scoped>

.buttons-block{
    margin-top: 2rem;
    display: flex;
    justify-content: space-evenly;
}
.accept-button{
    flex-grow: 1;
}

.option{
    margin: 0.5rem 0;
    padding: 1rem 0.5rem;
    border: 3px solid #ffe5b4;
    background-color: #fff6e6;
    border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    transition: background-color var(--transition-ease);
}
.option.active{
    background-color: #ffe7bb;
    border: 3px solid #ffd78b;
}
.option:hover{
    filter: contrast(1.1);
}
.option>label{
    margin: 0.2rem 0.3rem;
    flex-grow: 1;
}
.option>input[type=text]{
    margin: 0.5rem 5% 0 10%;
    width: 85%;
}
.option>button{
    /* float: right; */
    margin: 0 0.5rem;
    background-color: #ffbd6f;

}
.page-title{    
    width: 100%;
    text-align: center;
    border: 1px solid #eaae66;
    margin: 0.5rem;
    background: #f5cd9d;
    padding: 0.5rem;
    color: #653803;
    font-weight: 700;
}
</style>